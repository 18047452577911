.flexslider {
  margin: 0;
  border: 0;
  display: block !important;
  .slides {
    > li {
      background-position-x: center;
      background-position-y: center;
      background-size: cover;
      background-attachment: fixed;
      background-repeat: no-repeat;

      .intro-wrapper {
        display: table;
        width: 100%;
        height: 100%;

        > .intro {
          display: table-cell;
          vertical-align: middle;
          position: relative;
          z-index: 1;
        }
      }
    }

    img {
      max-height: 500px;
      text-align: center;
      margin: 0px auto;
      width: auto;
    }
  }
}

.slider-classic{
  display: block;
  height: auto;
  overflow-y: hidden;
  .flexslider{
    .slides {
      > li {
        height: auto !important;
        padding: 200px 0 90px 0;
        min-height: auto !important;
      }
    }
  }
}

.flex-direction-nav a{
  height: 50px;
}
.flex-direction-nav a.flex-prev:before {
  content: "\f3d2";
}
.flex-direction-nav a.flex-next:before {
  content: "\f3d3";
}
.flex-direction-nav a:before{
  font-family: "Ionicons";
  color: #000;
}