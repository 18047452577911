html,
body {
	min-height: 100%;
	color: #595959;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	line-height: 1.45;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}


h1, 
h2, 
h3, 
h4, 
h5{
	font-family: 'Raleway', sans-serif;
	color: #000000;
}

h1{
	font-size: 60px;
    font-weight: 100;
    letter-spacing: 8px;
    margin-bottom: 15px;
    margin-top: 0;
    text-transform: uppercase;

    @media only screen and (max-width: 767px) {
    	font-size: 42px;
  		letter-spacing: 4px;
    }
}

h2{
	font-size: 36px;
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0px;
    word-spacing: 8px;
    letter-spacing: 2px;
    @media only screen and (max-width: 767px) {
	  font-size: 30px;
		letter-spacing: 1px;
		word-spacing: 2px;
    }
}

h3{
  font-size: 30px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

h4{
	font-size: 18px;
	word-spacing: 3px;
	text-transform: uppercase;
	letter-spacing: 3px;
	margin-bottom: 25px;

	@media only screen and (max-width: 992px) {
    	letter-spacing: 1px;	
    }
}
h5 {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 15px;
}
h6{
	text-transform: uppercase;
	font-size: 12px;
	letter-spacing: 1px;
}

p{
	font-size: 14px;
  	line-height: 1.6;
	letter-spacing: 0.025em;
	&.led {
	  font-size: 1.2em;
	  margin-bottom: 40px;
	  &:first-letter {
		font-size: 4em;
		float: left;
		line-height: 50px;
	  }
	}
}

.dark-bg{
	h1,
	h2,
	h3{
		color: #ffffff;
	}
}

blockquote p {
  font-family: serif;
  font-style: italic;
  font-size: 18px;
  letter-spacing: 0;
}

.plain-list{
  li{
	line-height: 2.2em;
  }
}