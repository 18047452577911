.video-player {
  background: url(/assets/images/backgrounds/fpHarbor_sa.jpg) no-repeat center bottom;
  background-size: cover;
  color: $content-color-on-dark-bg;
  text-transform: uppercase;

  span {
    font-size: 9px;
  }

  .video-left, .video-right {
    text-align: center;
    font-weight: 300;
    font-size: 36px;
    line-height: 36px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0px;

    @media only screen and (max-width: 991px) {
      margin: 20px 0px;

    }
  }

  .video-icon {
    color: $content-color-on-dark-bg;
    font-size: 38px;
    border: 1px solid $content-color-on-dark-bg;
    height: 68px;
    position: relative;
    display: block;
    margin: 4px auto 0;
    max-width: 75px;

    i{
      position: absolute;
      top: 14px;
      left: 17px;
    }

    &:hover, &:active, &:focus {
      color: #000000;
    }
  }

  .video-right {
    color: $content-color-on-dark-bg;

    @media only screen and (max-width: 767px) {
      text-align: center;
    }
  }
}

@media (min-width: 991px) {
  .video-player {
    padding: 170px 0px;

    .video-right {

      h2 {
        margin-top: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  #video-info .download-icons {
    margin-bottom: 20px;
  }
}

#video-container{
  display: none;
}