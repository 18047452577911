/*-----------------*/
/* Global Styles   */
/*-----------------*/

.btn {
    border-radius: 2px;
    font-size: 12px;
    padding: 10px 35px;
    letter-spacing: 2px;
    line-height: 6px;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.btn:hover, .btn:focus, .btn:active {
  color: white;
  outline: none;
}

.btn:hover:before, .btn:focus:before, .btn:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}






/*-----------------*/
/* Solid Buttons   */
/*-----------------*/


.btn-default {
    background-color: #fff;
    border: 1px solid #DFDFDF;
    color: #031313;

    &:before{
      background: #000000;
    }

  &:hover, &:active, &:focus {
      background-color: #fff;
      color: #fff;
    }

}

.btn-mild{
  &:before{
    background: #000000;
  }

  &:hover, &:active, &:focus {
    color: #fff;
  }
}


.btn-dark {
  background-color: #000;
  color: #fdfdfd;
  &:hover, &:active, &:focus {
    color: #000000;
  }
}





/*-----------------*/
/* Outline Buttons */
/*-----------------*/


.btn-default-o {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
}

.btn-default-o:hover, .btn-default-o:focus{
    color: #000000;
}


.btn-list {
  margin-bottom: 0;
  button{
    margin-right: 15px;
    margin-bottom: 15px;
  }
}

.btn.btn-round {
  border-radius: 14px;
  &.btn:before{
    border-radius: 14px;
  }
}

/*bootstrap buttons*/
.btn-lg {
  padding: 15px 60px;
}

.btn-sm {
  padding: 8px 20px;
}

.btn-xs {
  padding: 6px 10px;
}

.btn-primary{
  &:active, &:hover, &:focus{
    color: #337ab7;
  }
}

.btn-success{
  &:active, &:hover, &:focus{
    color: #5cb85c;
  }
}

.btn-info{
  &:active, &:hover, &:focus{
    color: #46b8da;
  }
}

.btn-warning{
  &:active, &:hover, &:focus{
    color: #eea236;
  }
}

.btn-danger{
  &:active, &:hover, &:focus{
    color: #d43f3a;
  }
}