.services {
    background: $bg-base-color;
    background-size: cover;

    .service {
        padding: 30px 15px;
        position: relative;
        margin-bottom: 20px;
        transition: all 0.3s cubic-bezier(0.63, 0.32, 0.05, 0.39) 0s;

        &.last{
            margin-bottom: 0;
        }

        @media only screen and (max-width: 992px) {
            padding: 2px;
            margin-bottom: 40px;
        }

        .service-icon span {
            font-size: 3em;
        }

    }

    .section-content .secondrow .service {
        margin-bottom: 0;
        @media only screen and (max-width: 767px) {
            margin-bottom: 40px;
            &.last{
                margin-bottom: 0;
            }
        }
    }

    .service::before {
        content: '';
        position: absolute;
        top: -3px;
        left: 1px;
        width: calc(100% - 2px);
        height: 103%;
        border-top: 1px solid rgba(253, 253, 253, 0);
        border-bottom: 1px solid rgba(253, 253, 253, 0);
        transition: all 0.5s cubic-bezier(0.63, 0.32, 0.05, 0.39) 0s;
    }

    .service::after {
        content: '';
        position: absolute;
        top: 1px;
        left: -3px;
        width: calc(100% + 6px);
        height: 99%;
        border-right: 1px solid rgba(253, 253, 253, 0);
        border-left: 1px solid rgba(253, 253, 253, 0);
        transition: all 0.5s cubic-bezier(0.63, 0.32, 0.05, 0.39) 0s;
    }

    .service:hover {
        background-color: #FAFAFA;
    }

    .service:hover::before,
    .service:hover::after, {
        border-color: #FAFAFA;
    }
}