@import 'common';
@import 'components';
@import 'cta';
@import 'typography';
@import 'fonts';

@import 'buttons';
@import 'nav';
@import 'header';
@import "header-classic";
@import 'header_inner';
@import 'flexslider';
//@import 'team';
@import 'expertise';
@import 'video';
@import 'portfolio';
//@import 'testimonial';
@import 'about';
@import 'services';
//@import 'funfacts';
@import 'contact';
//@import 'clients';
//@import 'blog';
//@import 'pricing';
//@import 'subscribe';
@import 'why-choose-us';
//@import 'our-process';
@import "gallery";
//@import "faq";
@import 'footer';
@import "footer-widgets";
@import "404";
@import "ie_fix";
