.four-o-four {
  display: table;
  height: 100vh;
  width: 100%;
  color: #fff;

  h1, h2, h3, h5 {
    color: #fff;
  }

  h2 {
    font-size: 40px;
  }

  background-image: url(/assets/images/backgrounds/skills.jpeg);
  background-size: cover;
  background-position: 20% center;
  background-repeat: no-repeat;

  @media (max-width: 767px){
    text-align: center;
  }

  .four-o-four-inner {
    display: table-cell;
    vertical-align: middle;
    h1{
      font-weight: bold;
    }
  }

}