.about-us {
	
	.section-content {
		
		.about-content{
			text-align: center;
			margin-bottom: 0;
			padding: 0px;

			&.left{
				text-align: right;
			}

			&.right{
				text-align: left;
			}

			&.bottom{
				margin-top: 10px;
				margin-bottom: 25px;
			}
			
			@media only screen and (max-width: 992px) {
				padding: 0;
			}

			@media only screen and (max-width: 767px) {
				margin-bottom: 30px;
				&.left, &.right{
				  text-align: center;
				}
			  &.center {
				margin-bottom: 40px;
			  }
				&.right{
					margin-bottom: 0;
				}
			}
		}

		.about-content

		figure.about-content{
		  padding-top: 10px;
		}
		h4 {
			margin-top: 25px;
			@media only screen and (max-width: 992px) {
				margin-top: 0;
			  margin-bottom: 15px;
			}
		}
	}
}