.portfolio {
    background-color: $bg-base-color;
    padding-bottom: 0;

    .section-content {
        color: #fdfdfd;

        .portfolio-images {
            height: 400px;
            overflow-x: hidden;
            overflow-y: auto;
            margin-bottom: 26px;
            position: relative;
        }

        .isotop-filter {
            text-align: center;
            margin-bottom: 35px;
        }

        .element-item {
            margin-bottom: 15px;
            cursor: pointer;
        }

    }

    .portfolio-grid{
            padding: 5px;
    }
}



.da-thumbs {
    list-style: none;
    position: relative;
    padding: 0;
    margin: 0;
}
.da-thumbs li {
    float: left;
    background: #fff;
    padding: 0px;
    position: relative;
    width: 25%;
    padding: 5px;
    display: table;

    @media only screen and (max-width: 992px) {
        width: 33%;
    }

    @media only screen and (max-width: 767px) {
        width: 50%;
    }

}


.portfolio-grid {
    &.three-col{
        li {
            width: 33.333%;
            @media only screen and (max-width: 992px) {
                width: 33%;
            }

            @media only screen and (max-width: 767px) {
                width: 50%;
            }
        }
    }
    &.two-col{
        li {
            width: 50%;
        }
    }
}



.da-thumbs li.x2{
    width: 50%;
}
.da-thumbs li a,
.da-thumbs li a img {
    display: block;
    position: relative;
    vertical-align: middle;
    margin: 0px auto;
    max-height: 280px;
}
.da-thumbs li a {
    overflow: hidden;
    display: table-cell;
    height: 280px;
    border: 1px #000 solid;
}
.da-thumbs li a .portfolio-detail-overlay {
    position: absolute;
    background: #fff;
    background: rgba(0, 0, 0, 0.9);
    padding: 20px;
    text-align: center;
    width: 100%;
    height: 100%;
    display: none;
}
.da-thumbs li a div span {
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.85);
    padding: 2px;
    font-size: 12px;
    display: block;
    letter-spacing: 1px;
}

.portfolio-detail-overlay h4 {
    color: #FFF;
    margin-top: 0px;
    font-size: 14px;
    margin-bottom: 15px;
    letter-spacing: 2px;
    font-family: 'Rototo' sans-serif;
}


.portfolio-detail-overlay{
    .middle-align-wrap{
        display: table;
        height: 100%;
        width: 100%;
        .middle-align-cell{
            display: table-cell;
            vertical-align: middle;
        }
    }
}


.btn-isotop {
    background: none;
    border: 0;
    margin-top:9px;
    padding: 6px 20px 15px;
    color: #000;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    overflow: hidden;

    &:before{
        content: "";
        position: absolute;
        z-index: -1;
        left: 50%;
        right: 50%;
        top: 20px;
        background: #000000;
        height: 1px;
        -webkit-transition-property: left, right;
        transition-property: left, right;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    &:active,
    &:focus,
    &:hover{
        color: #000;
        box-shadow: none;
        outline: none !important;

        &:before{
            left: 0;
            right: 0;
        }
    }
}




.portfolio-details-single {
    margin-top: 60px;
    h3 {
        margin-bottom: 40px;
        position: relative;
        &:before {
            position: absolute;
            content: '';
            width: 60px;
            background-color: black;
            height: 1px;
            bottom: -15px;
        }
    }
    ul{
        list-style: none;
        line-height: 2.5em;
        padding-left: 0;
    }
}
