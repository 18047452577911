.footer-widgets{
  background: #171717;
  color: #cbc9cf;
  h4{
    color: #FFFFFF;
    margin-bottom: 40px;
    padding-bottom: 25px;
    border-bottom: 1px solid #3c3c3c;
  }

  .content-wrap{
    &.widget-text{

    }
    &.widget-contact {
      ul{
        padding: 0;
        list-style: none;
        margin: 0;
        li{
          margin-bottom: 25px;
          i{
            margin-right: 6px;
          }
          a {
            color: #cbc9cf;

            &:hover {
              color: #fff;
            }
          }
        }
      }
    }
    &.widget-recent-posts{
      padding-left: 25px;
      ul{
        padding: 0;
        list-style: none;
        margin: 0;
        li{
          margin-bottom: 20px;
          .post-title{
            display: block;
            color: #ffffff;
          }
          .author{
            color: inherit;
          }
        }
      }
    }
    &.widget-gallery{
      a{
        display: block;
        width: 45%;
        margin-right: 5%;
        margin-bottom: 5%;
        height: auto;
        float: left;
        background-size: cover;
        transition: opacity 0.2s;
        &:hover{
          opacity: 0.75;
        }
      }
    }

    @media only screen and (max-width: 991px) {
      margin-bottom: 80px;
    }
  }
}